// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2020-js": () => import("./../../../src/pages/2020.js" /* webpackChunkName: "component---src-pages-2020-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aether-js": () => import("./../../../src/pages/aether.js" /* webpackChunkName: "component---src-pages-aether-js" */),
  "component---src-pages-aqua-1-js": () => import("./../../../src/pages/aqua_1.js" /* webpackChunkName: "component---src-pages-aqua-1-js" */),
  "component---src-pages-bleistift-js": () => import("./../../../src/pages/bleistift.js" /* webpackChunkName: "component---src-pages-bleistift-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-feder-js": () => import("./../../../src/pages/feder.js" /* webpackChunkName: "component---src-pages-feder-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kohle-js": () => import("./../../../src/pages/kohle.js" /* webpackChunkName: "component---src-pages-kohle-js" */),
  "component---src-pages-kugel-1-js": () => import("./../../../src/pages/kugel_1.js" /* webpackChunkName: "component---src-pages-kugel-1-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-vita-js": () => import("./../../../src/pages/vita.js" /* webpackChunkName: "component---src-pages-vita-js" */)
}

